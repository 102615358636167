<template>
  <div class="myManage_main">
    <hotCircle
      :is-show-no-data="true"
      no-data-description="暂未管理任何圈子"
      :isComeManage="true"
      :ContSet="ContSet"
      :is-loaded="isLoaded"
      @myGlCircle="handleMyGlCircle"
      :hot_list="circleList"
      :noDataInfo="noDataInfo"></hotCircle>
    <van-action-sheet
      v-model="vanActionShow"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @select="handleSelect"
      @cancel="onCancel"
    ></van-action-sheet>
  </div>
</template>

<script>
import hotCircle from '@/components/hotCircle'
import {setDismissCircle, setQuitCircle} from "@/api/circle";

export default {
  name: "myManage",
  components: {
    hotCircle
  },
  props: {
    // 是否加载完毕
    isLoaded: {
      type: Boolean,
      default: false
    },
    circleList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      ContSet: {
        title_if: false,
      },
      vanActionShow: false,
      actions: [], // 设置圈子提示类型
      noDataInfo: {
        title1: '',
        title2: ''
      },
      currentOperaItem: {} // 当前操作的item
    }
  },
  methods: {
    /**
     * 圈子列表回传id
     */
    handleMyGlCircle(item) {
      // console.log(item)
      this.currentOperaItem = item
      if (item.identity === 1) {
        this.actions = [
          {name: '管理圈子'},
          {name: '解散圈子'},
        ]
      } else {
        this.actions = [
          {name: '管理圈子'},
          {name: '退出圈子'},
        ]
      }
      this.vanActionShow = true
    },
    /**
     * todo 解散圈子
     */
    async setDismissCircle() {
      const id = this.currentOperaItem.id
      if (id) {
        await setDismissCircle(id)
        this.$emit('dismissCircle', this.currentOperaItem.id)
      }
    },
    // 取消
    onCancel() {

    },
    /**
     * todo 退出圈子
     */
    async setQuitCircle() {
      const id = this.currentOperaItem.id;
      console.log(id);
      if (id) {
        await setQuitCircle(id);
        this.$emit('quitCircle', this.currentOperaItem.id)
      }
    },
    // 下拉选择菜单
    handleSelect(item) {
      // console.log(item)
      if (item.name === '退出圈子') {
        this.$dialog.confirm({
          title: '退出圈子',
          message: '你确定要退出该圈子吗？退出后你将失去管理员权限',
          confirmButtonText: '退出圈子',
          cancelButtonText: '再考虑下'
        }).then(()=>{
          this.setQuitCircle()
        })
      } else if (item.name === '解散圈子') {
        this.$dialog.confirm({
          title: '解散圈子',
          message: '解散后无法还原，确定解散当前圈子吗？',
          confirmButtonText: '解散圈子',
          cancelButtonText: '再考虑下'
        }).then(() => {
          console.log(this.currentOperaItem)
          this.setDismissCircle()
        })
      } else if (item.name === '管理圈子') {
        this.$router.push({
          path: '/app/circle/circleJbglsz',
          query: {
            id: this.currentOperaItem.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.myManage_main {
  padding: 0 0 10px;

  ::v-deep .van-dialog__message {
    white-space: pre-wrap !important;
  }
}
</style>
