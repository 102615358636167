<template>
  <div class="myCircleItem_main">
    <hotCircle
      :is-show-no-data="true"
      no-data-description="暂未加入任何圈子"
      @joinBtn="joinBtn"
      @hotClick="hotClick"
      :ContSet="ContSet"
      :is-loaded="isLoaded"
      :hot_list="circleList"
      :noDataInfo="noDataInfo"></hotCircle>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      @select="select"
      cancel-text="取消"
      close-on-click-action
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import hotCircle from '@/components/hotCircle'

export default {
  name: "myCircleItem", // 我加入的圈子
  components: {
    hotCircle
  },
  props: {
    // 是否加载完毕
    isLoaded: {
      type: Boolean,
      default: false
    },
    circleList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      ContSet: {
        title_if: false,
      },
      noDataInfo: {
        title1: '',
        title2: ''
      },
      show: false,
      actions: [
        {name: '退出'}
      ],
      currList: {}
    }
  },
  methods: {
    // 点击回调
    hotClick(item) {
      console.log(item, '回调数据')
    },
    // 按钮返回
    joinBtn(item) {
      this.currList = item
      if (item.type === 0) {
        this.show = true
      }
    },
    // 取消
    onCancel() {
      this.show = false
    },
    select(v, i) {
      console.log(this.currList, i)
      if (this.currList.role === 0) {
        this.$dialog.confirm({
          title: '提示',
          message: '你确定要退出吗？',
        })
          .then(() => {
            // on confirm
          })
      } else if (this.currList.role === 1) {
        this.$dialog.confirm({
          title: '提示',
          message: '您是当前圈子的管理员，退出后将不再拥有管理权，是否继续退出？',
        })
          .then(() => {
            // on confirm
          })
      } else {
        this.$dialog.confirm({
          title: '提示',
          message: '您是当前圈子的圈主，请在管理圈子中移交圈主后，才可操作退出',
        })
          .then(() => {
            // on confirm
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.myCircleItem_main {
  padding: 0 0 10px;
}
</style>
