<template>
  <div class="flex-column circleMy_main">
    <pageHead class="messageMainView_head" title="圈子" />

    <div class="tabBar">
      <van-icon @click.prevent="toSearch" size="18" color="#6d7278;" name="search" />
      <div class="bar_02">
        <div class="bar_02_1" v-for="item in tabList" :key="item.type">
          <div :class="currentIndex === item.type ? 'active' : 'active_no'" @click="tabBar_click(item.type)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div v-if="userDetail.isCanCreateCircle" class="bar_04" @click.stop="CreateCircle">建圈子</div>
      <div v-else></div>
    </div>
    <div class="flex-column circle-content">
      <div v-if="currentIndex === 1" style="border-top: 1px solid rgba(0, 0, 0, 0.08);"></div>
      <keep-alive>
        <AllCircle v-if="currentIndex === 1"></AllCircle>
        <myCircle v-else></myCircle>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import AllCircle from './components/allCircle'
import myCircle from './myCircle/myCircle.vue'
import {mapGetters} from "vuex";

export default {
  name: 'circleMy', // 我的圈子、全部圈子
  components: {
    pageHead,
    AllCircle,
    myCircle
  },
  data () {
    return {

      currentIndex: 0,
      tabList: [
        {
          title: '我的圈子',
          type: 0,
        },
        {
          title: '全部圈子',
          type: 1,
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userDetail']),
  },
  created () {
    // console.log(this.$route)
    console.log(localStorage.getItem('type'))
    // 调整下标指向页面
    this.currentIndex = JSON.parse(localStorage.getItem('type'))
  },
  methods: {
    tabBar_click (type) {
      this.currentIndex = type
    },
    // 跳转创建圈子
    CreateCircle () {
      this.$router.push({name: "myCreateCircle"});
    },
    // 跳转搜索页面
    toSearch () {
      this.$router.push({
        path: '/app/common/search',
        query: {
          id: 1,
          activeIndex: 1
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-action-sheet {
    padding-bottom: 60px;
  }
}

.circleMy_main {
  height: 100%;
  background: #fff;

  .pageHead {
    box-shadow: none;
  }

  .circle-content {
    flex: 1;
    overflow-y: hidden;
  }

  .tabBar {
    display: flex;
    justify-content: space-between;
    padding: 11px 18.5px 9px;
    margin-bottom: 16px;

    .bar_02 {
      font-size: 16px;
      line-height: 16px;

      .bar_02_1 {
        display: inline-block;
        margin-right: 32px;
      }

      .bar_02_1:last-child {
        margin-right: 0;
      }
    }

    .bar_04 {
      font-size: 13px;
      color: #fe5800;
      line-height: 13px;
    }
  }
}

.active {
  color: #000;
  position: relative;

  &::before {
    width: 22px;
    height: 4px;
    content: url('~@/assets/imgs/circle/Slice5s.svg');
    position: absolute;
    left: 17px;
    top: 12px;
  }
}

.active_no {
  color: #6D7278;
}

</style>
