<template>
  <div class="flex-column myCircle_main">
    <div class="tabBar">
      <div class="bar_02_1" v-for="item in tabList" :key="item.type">
        <div :class="currentIndex === item.type ? 'active' : 'active_no'" @click="tabBar_click(item.type)">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="flex-column my-list"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore">
      <div style="margin-top: 20px;" v-if="!isLoaded && (joinList.length === 0 || managedList.length === 0)">
        <van-skeleton title :row="20" />
      </div>
      <!--todo  我加入的圈子-->
      <myCircleItem :is-loaded="isLoaded" :circle-list="joinList" v-if="currentIndex === 0"></myCircleItem>
      <!--todo  我管理的圈子-->
      <myManage @quitCircle="handleDismissCircle" @dismissCircle="handleDismissCircle" :is-loaded="isLoaded" :circle-list="managedList" v-else></myManage>
    </div>
  </div>
</template>

<script>
import myCircleItem from './myCircleItem/myCircleItem.vue'
import myManage from './myManage'
import {getListJoinedCircle, getListManagedCircle} from "@/api/circle";

export default {
  name: "myCircle",
  components: {
    myCircleItem,
    myManage
  },
  data() {
    return {
      currentIndex: 0,
      finished: false,
      tabList: [
        {
          title: '我加入的圈子',
          type: 0,
        },
        {
          title: '我管理的圈子',
          type: 1,
        }
      ],
      joinList: [], // 加入的
      isLoaded: false,
      managedList: [], // 管理的
      paginationData: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    // 调整下标指向页面
    this.currentIndex = JSON.parse(localStorage.getItem('myCircleType'))
    if (this.currentIndex === '1' || this.currentIndex === 1) {
      this.getListManagedCircle()
    } else {
      this.currentIndex = 0
      this.getMyJoinList()
    }
  },
  methods: {
    /**
     * 解散圈子，从列表中删除该数据
     */
    handleDismissCircle(id) {
      this.managedList = this.managedList.filter(item => item.id !== id)
    },
    loadMore() {
      if (this.currentIndex === 0) {
        if (!this.finished && this.joinList.length > 0) {
          this.paginationData.pageNum++
          this.getMyJoinList()
        }
      } else {
        if (!this.finished && this.managedList.length > 0) {
          this.paginationData.pageNum++
          this.getListManagedCircle()
        }
      }
    },
    /**
     * todo 分页获取我管理的圈子列表
     */
    async getListManagedCircle() {
      try {
        const res = await getListManagedCircle(this.paginationData) || {}
        const {records} = res || []
        if (records.length < 10) {
          this.finished = true
        }
        this.managedList = this.managedList.concat(records)
      } finally {
        this.isLoaded = true
      }
    },
    /**
     * todo 获取我加入的圈子列表
     */
    async getMyJoinList() {
      try {
        const res = await getListJoinedCircle(this.paginationData) || {}
        const {records} = res || []
        if (records.length < 10) {
          this.finished = true
        }
        this.joinList = this.joinList.concat(records)
      } finally {
        this.isLoaded = true
      }
    },
    // 切换我的或我管理的
    tabBar_click(type) {
      localStorage.setItem('myCircleType', type)
      this.isLoaded = false
      this.currentIndex = type
      this.finished = false
      this.paginationData.pageNum = 1
      if (this.currentIndex === 0) {
        this.joinList = []
        this.getMyJoinList()
      } else {
        this.managedList = []
        this.getListManagedCircle()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  position: relative;
  color: #fe5800;
  border-radius: 14.5px;
  border: 1px solid #fe5800;
}

.active_no {
  color: #6d7278;
  border-radius: 14.5px;
  border: 1px solid #6D7278;
}

.myCircle_main {
  flex: 1;
  overflow: hidden;

  .my-list {
    flex: 1;
    overflow-y: auto;
  }

  .tabBar {
    display: flex;
    justify-content: space-around;
    padding: 0 50px 14px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
    line-height: 16px;

    .bar_02_1 {
      font-size: 12px;
      text-indent: 14px;
      line-height: 28px;
      width: 100px;
      height: 28px;
    }

    .bar_02_1:last-child {
      margin-right: 0;
    }
  }
}
</style>
