<template>
  <van-tree-select
    height="100%" @click-nav="handleClickNav" :items="contMenu" :main-active-index.sync="active">
    <template #content>
      <!--右边菜单数据  -->
      <hotCircle
        infinite-scroll-throttle-delay="500"
        infinite-scroll-distance="50"
        v-infinite-scroll="loadMore"
        :height="0"
        :is-show-no-data="true"
        :is-loaded="isLoaded"
        no-data-description="该分类下暂无圈子"
        @joinBtn="joinBtn"
        :ContSet="ContSet"
        :hot_list="hot_list"
        :noDataInfo="noDataInfo"></hotCircle>
      <p v-if="userDetail.isCanCreateCircle" class="flex-center-row cont_tile">
        <span>没有想加入？</span>
        <span style="font-weight:600;" class="colFE5800"
          @click.prevent="createMyCircle">创造我自己的圈子 ></span>
      </p>
    </template>
  </van-tree-select>
</template>

<script>
import hotCircle from '@/components/hotCircle'
import {getListCircleByType, getListCircleType} from "@/api/circle";
import {mapGetters} from "vuex";

export default {
  name: "AllManan",
  components: {
    hotCircle
  },
  computed: {
    ...mapGetters(['userDetail']),
  },
  data() {
    return {
      active: 0,
      activeTypeId: null, // 当前活跃的类型id
      finished: false, // 是否加载完毕，最后一页
      isLoaded: false, // 是否加载，请求过接口
      curSelectedLab: 0, //当前默认选中
      contMenu: [], // 左侧菜单栏
      ContSet: {
        title_if: false,
      },
      hot_list: [],
      noDataInfo: {
        title1: '',
        title2: ''
      },
      childListParams: {
        pageNum: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.getListCircleType()
  },
  methods: {
    loadMore() {
      if (!this.finished && this.hot_list.length > 0) {
        this.childListParams.pageNum++
        this.getListCircleByType()
      }
    },
    /**
     * 点击左侧菜单，加载对应的圈子列表
     */
    handleClickNav(value) {
      console.log(value)
      console.log(this.contMenu[value].id)
      this.finished = false
      this.childListParams.pageNum = 1
      this.activeTypeId = this.contMenu[value].id
      this.getListCircleByType(this.contMenu[value].id)
    },
    /**
     * 点击左侧菜单，加载对应的圈子列表
     */
    contMenuClick(item, index) {
      this.curSelectedLab = index;
      this.childListParams.pageNum = 1
      this.activeTypeId = item.id
      this.getListCircleByType()
    },
    // 跳转创建我的圈子
    createMyCircle() {
      this.$router.push('myCreateCircle')
    },
    joinBtn(item) {
      console.log(item, '8877')
    },
    /**
     * todo 获取分类下的圈子列表
     */
    async getListCircleByType() {
      try {
        const params = {
          circleTypeId: this.activeTypeId,
          ...this.childListParams
        }
        const res = await getListCircleByType(params) || {}
        const {records} = res || []
        if (records.length < 10) {
          console.log(records.length)
          this.finished = true
        }
        if (this.childListParams.pageNum > 1) {
          this.hot_list = this.hot_list.concat(records)
        } else {
          this.hot_list = records
        }
      } finally {
        this.isLoaded = true
      }
    },
    /**
     * todo H5-所有启用圈子类型
     */
    async getListCircleType() {
      let res = await getListCircleType() || []
      let AllList = [{
        id: "",
        title: "全部"
      }]
      console.log(res)
      if (res.length > 0) {
        res = AllList.concat(res)
        this.contMenu = res
        this.contMenu = this.contMenu.map(item => {
          let title = item.title
          if (title.length > 2) {
            title = title.substr(0, 2) + '...'
          }
          return {
            ...item,
            text: title
          }
        })
        this.activeTypeId = res[0].id
        await this.getListCircleByType()
      } else {
        this.activeTypeId = ''
        await this.getListCircleByType()
      }
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-tree-select {
    z-index: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }

  .van-sidebar-item--select::before {
    background-color: $mainColor;
  }

  .van-tree-select__nav {
    flex: none;
    width: 86px;
  }

  .van-tree-select__content {
    flex: 1;
  }
}

.cont_tile {
  padding-bottom: 16px;
  font-size: 12px;
  color: #6d7278;
}
</style>
